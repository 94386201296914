.skeleton {
  &-title {
    height: 1.5rem;
    width: 25%;
  }
  &-subtitle {
    height: 1.2rem;
    width: 50%;
  }
  &-row {
    &--full {
      height: 1rem;
    }
    &--half {
      height: 1rem;
      width: 60%;
    }
    &--full,
    &--half {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &-title,
  &-subtitle,
  &-row--full,
  &-row--half {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: -150px;
      top: 0;
      height: 100%;
      width: 150px;
      background: linear-gradient(
        90deg,
        rgba(lightgrey, 0) 0,
        rgba(lightgrey, 0.8) 50%,
        rgba(lightgrey, 0) 100%
      );
      background-repeat: no-repeat;
      // animation: load 1s infinite cubic-bezier(0.4, 0, 0.2, 1);
      animation: load 1.4s infinite linear;
    }
  }
}

div[class*='skeleton-'] {
  border-radius: 0.3rem;
  background-color: rgba(lightgrey, 0.7);
  position: relative;
  overflow: hidden;
}

@keyframes load {
  from {
    left: -150px;
  }
  to {
    left: 150%;
  }
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1);
  }
  50% {
    background-color: rgba(165, 165, 165, 0.3);
  }
  100% {
    background-color: rgba(165, 165, 165, 0.1);
  }
}
