// Fixes issues with bootstrap/theme variables and node-sass: https://github.com/twbs/bootstrap/issues/28240
$font-size-base: 0.875rem !default;
$line-height-lg: 3.375 !default;

@import 'node_modules/bootstrap/scss/bootstrap.scss';
@import 'paper-kit/paper-kit.scss';
@import 'styles/global-fixes.scss';

html {
  padding: env(safe-area-inset);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body#tinymce {
  padding: 0.375rem 0.75rem;
}

.tox.tox-tinymce {
  border-radius: 4px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
