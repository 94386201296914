$small: 768px;
$medium: 992px;
$large: 1200px;

@mixin xs {
  @media screen and (max-width: $small - 1px) {
    @content;
  }
}

@mixin sm {
  @media screen and (min-width: $small) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: $medium) {
    @content;
  }
}

@mixin lg {
  @media screen and (min-width: $large) {
    @content;
  }
}
