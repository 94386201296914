.login {
  &-container {
    height: calc(100vh - 71px);
    padding-bottom: 8rem;
  }

  &-form {
    width: 50%;
  }
}
